// eslint-disable-next-line no-unused-vars
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { keyframes } from "@emotion/react"

import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import Container from "../layout/container"
import BackgroundImage from "gatsby-background-image"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query PageQuery {
      Img: file(relativePath: { eq: "404/404.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 655, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Astronaut: file(relativePath: { eq: "404/Astronaut404.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 193, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Background: file(relativePath: { eq: "404/404Background.png" }) {
        childImageSharp {
          # Other options include height (set both width and height to crop),
          # grayscale, duotone, rotate, etc.
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const astronautAnimation = keyframes({
    "0%": {
      transform: "translateY(-200px) translateX(-60px)",
    },
    "50%": {
      transform: "translateY(-220px) translateX(-60px)",
    },
    "100%": {
      transform: "translateY(-200px) translateX(-60px)",
    },
  })

  return (
    <div
      sx={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: "center",
        flexDirection: `column`,
      }}
    >
      <BackgroundImage
        fluid={data.Background.childImageSharp.fluid}
        sx={{
          width: "100%",
          maxWidth: "1920px",
          height: "100vh",
        }}
      >
        <Container>
          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: "center",
              paddingTop: `6rem`,
            }}
          >
            <h1
              sx={{
                color: colors.code.secondary,
                fontWeight: fontWeights.black,
                fontSize: [`1.625rem`, "1.625rem", "36px", "36px"],
                marginBottom: `16px`,
                textAlign: `center`,
              }}
            >
              Watch out, we are on the wrong track!
            </h1>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: `16px`,
                color: colors.code.secondary,
                opacity: 0.8,
                textAlign: `center`,
              }}
            >
              The page you are looking for isn’t found :( We suggest you go back
              home.
            </p>
            <div
              sx={{
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                //transform: "translateY(-30px)",
              }}
            >
              <Img
                fluid={data.Img.childImageSharp.fluid}
                sx={{
                  width: ["300px", "400px"],
                  maxWidth: "655px",
                  top: "50%",
                }}
              />
              <Img
                fluid={data.Astronaut.childImageSharp.fluid}
                sx={{
                  width: ["90px", "130px"],
                  maxWidth: "193px",
                  top: ["50px", "0"],
                  left: ["55%", "50%"],
                  position: "relative",
                  animation: `${astronautAnimation} 3.2s infinite`,
                }}
              />
            </div>
          </div>
        </Container>
      </BackgroundImage>
    </div>
  )
}

export default Hero
