// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Hero from "../components/errorPage/hero"
import Layout from "../components/layout/layout"

const Page404 = ({ data, pageContext }) => {
  const langKey = pageContext.lang

  return (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <Layout langKey={langKey} allContentfulFooter={data.allContentfulFooter}>
        <Hero />
      </Layout>
    </>
  )
}

export default Page404

export const data = graphql`
  query ErrorPageQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
